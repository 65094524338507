.App {
  display: flex;
  text-align: center;
  font: 'Prompt';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Prompt";   /*Can be any text*/
  src: local("Prompt"),
    url("./assets/fonts/Prompt.ttf") format("truetype");
}

body, html { 
  font-family: 'Prompt';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 70vw;
  height: 100vh;
}

.sidebar {
  box-sizing: border-box;
  padding: 2em;
  width: 30vw;
}

.sidebar h2,
.sidebar p,
.sidebar ul {
  text-align: left;
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar button {
  width: 100%;
  font-weight: bold;
  padding: .8em 1em;
}

.pm25-class{
  font-weight: bold;
}

.Main-Map {
  padding: 0px;
  margin: 0px;
  /* margin-top: 64px; */
  height: 00px;
  width: 100%;
}

.leaflet-container {
  width: 100%; 
  height: 700px;
}